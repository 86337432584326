.container {
    position: relative;
    height: "20vh";
}


.overlay {
    position: absolute;
    left: 0;
    width: 230.75px;
    height: 410px;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
}

.container:hover .overlay {
    display: block;
    background: rgba(0, 0, 0, .4);
}

.container .text {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 18px;
    color: white;
    opacity: 1;
    /* Initially visible */
    transition: opacity 0.35s ease;
}

.container .text2 {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    color: white;
    opacity: 1;
    /* Initially visible */
    transition: opacity 0.35s ease;
}

.container:hover .text {
    opacity: 0;
    /* Hide text on hover */
}

// .im {
//     position: absolute;
//     height: 100%;
//     left: 0;
// }

.plays {
    top: 35%;
    left: 20%;
    opacity: 0;
    transition: opacity .35s ease;
}

.comments {
    top: 35%;
    left: 55%;
    opacity: 0;
    transition: opacity .35s ease;
}

.container:hover .plays {
    opacity: 1;
}

.container:hover .comments {
    opacity: 1;
}