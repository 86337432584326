$yellow:  rgb(248, 159, 0);
$cyan:    rgb(0, 172, 157);
$grey:    rgb(51, 51, 51);
$black:   rgb(38, 38, 38);
$base:    rgb(255, 255, 255);
$lightGrey:    rgb(235, 235, 235);

$BASE_FONT_SIZE: 16px;

$breakpointMega: 1600px;
$breakpointLarge: 990px;
$breakpointMed: 767px;
