/* styles.css */

.ais-SearchBox-input {
    width: 400px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px 0px 0px 5px;
}

.ais-SearchBox-submit {
    background-color: #E4F87F;
    color: #fff;
    border: none;
    height: 40px;
    padding: 10px 20px;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
    font-size: 16px;
}

.ais-SearchBox-reset {
    display: none
}