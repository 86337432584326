.trackingGradientCircle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: radial-gradient(#FFFFFF, var(--gradientColor));
    /* Set the color via JS */
}

.trackingGrid {
    padding-left: 64px;
    padding-right: 64px;
    display: flex;
    justify-content: center;
    align-items: center
}

.trackingInfluencersGrid {
    font-size: 12px;
}


.trackingInfluencersContentDelivered {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.trackingContentOuterCardMetrics {
    gap: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trackingContentInnerCardContentPost {
    width: 350px;
    height: 450px;
    border-radius: 20px;
    background-color: black;
    border: 1px solid black;
}

.trackingContentInnerCardMetrics {
    display: flex;
    justify-content: center;
    font-size: 28px;
    margin-bottom: 8px;
    font-weight: bold
}

.trackingContentInnerCardMetricLabels {
    display: flex;
    justify-content: center;
    gap: 3px;
}

.trackingContentInnerCardEditMetricGridContainer {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
    text-align: center;
}

.grid-item:nth-child(1) {
    grid-column: span 3;
}

.grid-item:nth-child(2) {
    grid-column: span 3;
}

.grid-item:nth-child(3) {
    grid-column: span 2;
}

.grid-item:nth-child(4) {
    grid-column: span 2;
}

.grid-item:nth-child(5) {
    grid-column: span 2;
}

.activeTab {
    display: block;
}

.pieChartHeading {
    text-align: center;
    margin-top: 10px;
    font-size: 34px
}

.avatarTopContent {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.textTopContent {
    font-size: 16px;
}

@media (max-width: 1000px) {
    .trackingGradientCircle {
        width: 30px;
        /* Smaller width for small screens */
        height: 30px;
        /* Smaller height for small screens */
    }

    .trackingGrid {
        padding-left: 24px;
        padding-right: 24px;
    }

    .trackingInfluencersGrid {
        font-size: 12px;
    }

    .trackingInfluencersContentDelivered {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .trackingContentOuterCardMetrics {
        gap: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }

    .trackingContentInnerCardContentPost {
        width: 250px;
        height: 350px;
        border-radius: 20px;
        background-color: black;
        border: 1px solid black;
    }

    .trackingContentInnerCardMetrics {
        display: flex;
        justify-content: center;
        font-size: 18px;
        margin-bottom: 8px;
        font-weight: bold
    }

    .grid-item:nth-child(1) {
        grid-column: span 3;
    }

    .grid-item:nth-child(2) {
        grid-column: span 3;
    }

    .grid-item:nth-child(3) {
        grid-column: span 3;
    }

    .grid-item:nth-child(4) {
        grid-column: span 3;
    }

    .grid-item:nth-child(5) {
        grid-column: span 3;
    }

    .activeTab {
        display: none;
    }

    .pieChartHeading {
        text-align: center;
        margin-top: 10px;
        font-size: 24px
    }

    .avatarTopContent {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

    .textTopContent {
        font-size: 12px;
    }
}