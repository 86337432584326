@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,700italic,400italic);
@import "base/reset";
@import "base/clearfix";
@import "base/variables";
@import "base/mixins";

html {
  font-size: $BASE_FONT_SIZE;
}

body {
  font-family: 'Roboto', sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


@import "components/Collapsible";